<template>
  <b-card>
    <b-tabs
      content-class="col-12 col-md-12 mt-12 mt-md-0"
      pills
      nav-wrapper-class="col-md-12 col-12"
      nav-class="nav-left"
    >
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="ListIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t("Ülke") }}</span>
        </template>
        <validation-observer ref="ulkeRules">
          <b-form class="mt-1">
            <b-row>
              <b-col cols="6" md="6" lg="4">
                <b-form-group>
                  <label for="sUlkeAdi">
                    {{ $t("Ülke") }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Ülke"
                  >
                    <b-form-input
                      id="sUlkeAdi"
                      v-model="UlkeData.sAd"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
             <b-row class="mt-2">
              <b-col>
                <save-button
                  :onClickSaveAndClose="saveUlke.bind(true)"
                  :onClickSave="saveUlke.bind(false)"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t("Şehir") }}</span>
        </template>
        <validation-observer ref="sehirRules">
          <b-form class="mt-1">
            <b-row>
              <b-col cols="6" md="6" lg="4">
                <b-form-group>
                  <label for="sSehirAdi">
                    {{ $t("Ülke") }}
                  </label>
                            <v-select
                        v-model="SehirData.sUlkeGuid"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        placeholder="Ülke"
                        :options="getUlkeler"
                        :getOptionLabel="option => option.sAd"
                        :reduce="option => option.sGuid"
                      />
                
                </b-form-group>
              
              </b-col>
                <b-col cols="6" md="6" lg="4">
                  <b-form-group>
                    <label for="sSehirAdi">
                    {{ $t("Şehir") }}
                  </label>
                      <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Şehir"
                  >
                    <b-form-input
                      id="sSehirAdi"
                      v-model="SehirData.sAd"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                </b-col>
            </b-row>
             <b-row class="mt-2">
              <b-col>
                <save-button
                  :onClickSaveAndClose="saveSehir.bind(true)"
                  :onClickSave="saveSehir.bind(false)"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t("Semt") }}</span>
        </template>
        <validation-observer ref="semtRules">
          <b-form class="mt-1">
            <b-row>
              <b-col cols="6" md="6" lg="4">
                 <b-form-group>
                  <label for="sSehirAdi">
                    {{ $t("Ülke") }}
                  </label>
                            <v-select
                        v-model="SemtData.sUlkeGuid"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        placeholder="Ülke"
                        :options="getUlkeler"
                        @input="fetchSehirler(SemtData.sUlkeGuid)"
                        :getOptionLabel="option => option.sAd"
                        :reduce="option => option.sGuid"
                      />
                
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6" lg="4">
                 <b-form-group>
                  <label for="sSehirAdi">
                    {{ $t("Şehir") }}
                  </label>
                      <v-select
                        v-model="SemtData.sSehirGuid"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        placeholder="Şehir"
                        :options="getSehirler"
                        :getOptionLabel="option => option.sAd"
                        :reduce="option => option.sGuid"
                      />
                
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <label for="sSemtAdi">
                    {{ $t("Semt") }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Semt"
                  >
                    <b-form-input
                      id="sSemtAdi"
                      v-model="SemtData.sAd"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <save-button
                  :onClickSaveAndClose="saveSemt.bind(true)"
                  :onClickSave="saveSemt.bind(false)"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import SaveButton from '@/components/SaveButton.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  required,
  min,
  max,
} from '@validations';
import useAdres from '@/views/management/tanim-yonetim/adres/useAdres';

// Notify
import AlertService from '@/common/alert.service';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },
  setup() {
    const {
      getUlkeler,
      fetchSehirler,
      getSehirler,
      getSemtler,

    } = useAdres()

    return {
      getUlkeler,
      fetchSehirler,
      getSehirler,
      getSemtler,
    }
  },

  data() {
    return {
      required,
      min,
      max,
      UlkeData: {
        sAd: null,
        sSehirGuid: null,
        sUlkeGuid: null,
      },
       SehirData: {
        sAd: null,
        sSehirGuid: null,
        sUlkeGuid: null,
      },
       SemtData: {
        sAd: null,
        sSehirGuid: null,
        sUlkeGuid: null,
      },
    };
  },

  methods: {
    saveUlke(isClose) {
      this.$refs.ulkeRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('saveUlke', this.UlkeData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                if (isClose) {
                  this.$router.push({ name: 'manage-adres' });
                }
              } else {
                AlertService.error(this, response.message);
              }
            });
        }
      });
    },
     saveSehir(isClose) {
      this.$refs.sehirRules.validate().then(success => {
        if (success && this.SehirData.sUlkeGuid) {
          this.$store
            .dispatch('saveSehir', this.SehirData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                if (isClose) {
                  this.$router.push({ name: 'manage-adres' });
                }
              } else {
                AlertService.error(this, response.message);
              }
            });
        } else {
          AlertService.error(this, 'Ülke seçiniz');
        }
      });
    },
     saveSemt(isClose) {
      this.$refs.semtRules.validate().then(success => {
        if (success && this.SemtData.sUlkeGuid && this.SemtData.sSehirGuid) {
          this.$store
            .dispatch('saveSemt', this.SemtData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                if (isClose) {
                  this.$router.push({ name: 'manage-adres' });
                }
              } else {
                AlertService.error(this, response.message);
              }
            });
        } else {
          AlertService.error(this, 'Ülke / şehir seçiniz');
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>
